import isStrongPassword from 'validator/lib/isStrongPassword';
import { z } from 'zod';

export const loginFormSchema = z.object({
  email: z.string({ required_error: 'Email is required' }).email({ message: 'Email is not valid' }),
  password: z.string({ required_error: 'Password is required' }).refine(
    (password) =>
      isStrongPassword(password, {
        minLength: 8,
        minNumbers: 1,
        minSymbols: 1,
        minUppercase: 1,
        returnScore: false,
      }),
    {
      message:
        'Password must be at least 8 characters long and contain at least one uppercase character, one number, and one special character',
    },
  ),
  redirectTo: z.optional(z.string()),
});
