import { useState } from 'react';
import { isRouteErrorResponse, Link, useActionData, useLocation, useRouteError } from '@remix-run/react';
import { withZod } from '@remix-validated-form/with-zod';
import { type LinksFunction, type MetaFunction } from '@vercel/remix';
import { $path } from 'remix-routes';
import { useHydrated } from 'remix-utils/use-hydrated';
import { ValidatedForm } from 'remix-validated-form';

import { useEventTracking } from '~/src/shared/integrations/segment/hooks/useEventTracking';
import { OnboardingLayout } from '~/src/shared/layouts';
import { getSeo } from '~/src/shared/seo';
import { FormInput, SubmitButton } from '~/src/ui';
import { Empty } from '~/src/ui/old/empty';
import { OnboardHeaderGroup } from '../onboarding/OnboardHeaderGroup';
import { LoginResponseHandler } from './LoginResponseHandler';
import { loginFormSchema } from './schema/loginFormSchema';

export { action, loader } from './_login.server';

export default function LogIn() {
  const { search } = useLocation();
  const redirectTo = new URLSearchParams(search).get('redirectTo') || undefined;
  const error = useActionData<{ errorCode: string }>();

  const isHydrated = useHydrated();
  const { trackEvent } = useEventTracking();
  const [email, setEmail] = useState<string>();

  return (
    <OnboardingLayout>
      <OnboardHeaderGroup
        heading='Welcome back to Kota'
        subHeading='Create global retirement, healthcare packages and more - for you, your employees or your customers, in minutes, not weeks'
      />

      <ValidatedForm
        noValidate={isHydrated}
        validator={withZod(loginFormSchema)}
        defaultValues={{
          redirectTo,
        }}
        method='POST'
        className='flex w-full max-w-xl flex-col gap-4'
        autoComplete='on'
      >
        <FormInput hidden type='string' name='redirectTo' label='' />

        <FormInput
          autoFocus
          onClick={() => trackEvent('Web: Login - Work Email Input - Clicked')}
          onChange={(e) => setEmail(e.target.value)}
          label='Work Email'
          type='email'
          name='email'
          placeholder='jane.doe@acme.com'
          data-testid='email'
        />

        <div className='flex flex-col gap-1'>
          <FormInput
            onClick={() => trackEvent('Web: Login - Password Input - Clicked')}
            label='Password'
            name='password'
            type='password'
            canTogglePassword
            data-testid='password'
          />

          <Link
            prefetch='intent'
            onClick={() => trackEvent('Web: Login - Forgot Password Link - Clicked')}
            to={$path('/forgot-password', email && { email: encodeURIComponent(email) })}
            // to={forgotPasswordRoutes.root(email)}
            className='primary-link'
          >
            Forgot your password?
          </Link>
        </div>

        {error?.errorCode ? <LoginResponseHandler error={error.errorCode} /> : null}

        <SubmitButton data-testid='submit' onClick={() => trackEvent('Web: Login - Button - Clicked')}>
          Sign In
        </SubmitButton>

        <div className='flex flex-wrap items-center justify-center gap-1'>
          <p className='text-xs font-normal text-gray-400'>Don't have an account?</p>

          <Link
            prefetch='intent'
            onClick={() => trackEvent('Web: Login - Sign Up Link - Clicked')}
            to={$path('/onboarding')}
            className='primary-link'
          >
            Sign Up
          </Link>
        </div>
      </ValidatedForm>
    </OnboardingLayout>
  );
}

export const meta: MetaFunction = () => getSeo('Login');

export const links: LinksFunction = () => {
  const images = ['/images/login-app-screenshot.png', '/images/login-blobs-bg.png'];

  return images.map((image) => ({ rel: 'prefetch', href: image, as: 'image' }));
};

export function ErrorBoundary() {
  const error = useRouteError();

  return (
    <div className='flex w-full max-w-xl grow items-center justify-center'>
      <Empty
        image='/images/app/error/error.svg'
        title={`Sorry, this shouldn't have happened`}
        description={
          isRouteErrorResponse(error) ? error.data.message : 'We are working to fix this issue. Please try again later.'
        }
      />
    </div>
  );
}
