/* eslint-disable react/button-has-type */

import * as React from 'react';

import { loginErrorEnum } from '@yonderlabs-packages/schemas-api';

import { Alert } from '~/src/ui';

interface ILoginResponseProps {
  error: unknown;
}

export function LoginResponseHandler({ error }: ILoginResponseProps) {
  const formatLoginAlertMap = {
    [loginErrorEnum.enum.InvalidCredentials]:
      'The email address or password you have entered is invalid. Please try again. If you have forgotten your password, please click the link above.',
    [loginErrorEnum.enum.EmailVerificationRequired]: 'Please verify your email address before logging in.',
    [loginErrorEnum.enum.InvalidClientCredentials]:
      'The client credentials you have entered are invalid. Please enter valid client credentials.',
    [loginErrorEnum.enum.MfaInvalid]: 'The MFA code you have entered is invalid. Please enter a valid MFA code.',
    [loginErrorEnum.enum.MfaRequired]: 'Please enter the MFA code sent to your email address.',
    [loginErrorEnum.enum.PhoneNumberVerificationRequired]: 'Please verify your phone number before logging in.',
    [loginErrorEnum.enum.UserDoesntExist]: 'This user does not exist. Please enter valid client credentials.',
    [loginErrorEnum.enum.UserLockedOut]: 'This user has been locked out. Please contact your administrator.',
    [loginErrorEnum.enum.UnknownError]: 'An error occurred, please contact your administrator',
  };

  const parsedError = loginErrorEnum.safeParse(error);

  const handleError = () => {
    if (parsedError.success) {
      return formatLoginAlertMap[parsedError.data];
    }
    return 'An error occurred, please contact your administrator';
  };

  return <Alert variant='danger' message={handleError()} />;
}
